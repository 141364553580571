<template>
  <v-card shaped class="pa-3">
    <v-row class="ma-3">
      <v-col cols="12">
        <v-text-field rounded outlined dense hide-details v-model="item.radius" label="Радиус"/>
      </v-col>
      <v-col cols="12">
        <v-switch rounded outlined dense hide-details v-model="item.limit_the_couriers_radius" label="Ограничить курьеров"/>
      </v-col>
    </v-row>
    <v-row class="justify-space-around pb-3">
      <v-btn rounded outlined elevation="3" color="primary" @click="save()">Сохранить</v-btn>
    </v-row>
  </v-card>
</template>

<script>
import {uuid} from "uuidv4";
import axios from "@/services/axios";

export default {
  data() {
    return {
      item: {
        id: uuid.Nil,
        radius: 0,
        limit_the_couriers_radius: false
      },
    }
  },

  mounted() {
    this.fetch()
  },

  methods: {
    fetch() {
      axios.send({
        url: "/settings",
        method: "GET"
      }).then(resp => {
        this.item = resp.data;
      })
    },
    save() {
      this.item.radius = parseInt(this.item.radius)
      axios.send({
        url: "/settings/",
        method: "POST",
        data: this.item
      }).then(() => {
        this.$router.go();
      })
    }
  }
}
</script>
